export default [
  {
    text: 'stats.table.createdAt',
    value: 'createdAt',
    default: true,
    align: 'left',
    sortable: false,
    type: 'slot',
  },
  {
    text: 'stats.table.level',
    value: 'level',
    default: true,
    align: 'left',
    sortable: false,
  },
  {
    text: 'stats.table.source',
    value: 'source',
    default: true,
    align: 'left',
    sortable: false,
  },
  {
    text: 'stats.table.logs',
    value: 'logs',
    default: true,
    align: 'left',
    sortable: false,
    type: 'slot',
  },
];
