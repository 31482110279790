
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import OrderTable from '@/components/statistics/OrderTable.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import StatisticFilter from '@/components/statistics/StatisticFilter.vue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import StatisticLoader from '@/components/statistics/StatisticLoader.vue';
import { Order } from '@/interfaces/models/Order';
import Filter from '@/interfaces/api/Filter';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import moment from 'moment';
import { Permission } from '@/enums/Permission';
import { Page } from '@/interfaces/api/Page';
import EditArticleModal from '@/views/article/EditArticleModal.vue';
import { OrderStatus } from '@/enums/OrderStatus';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';

const statistics = namespace('statistics');
const venue = namespace('venue');
const venueLabel = namespace('venueLabel');

@Component({
  components: { EditArticleModal, StatisticLoader, VRangePicker, StatisticFilter, VWrapper, OrderTable },
})
export default class Statistics extends mixins(Notification) {
  public $refs!: {
    table: OrderTable & { resetPage: () => void };
  };

  public showEditArticleModal: boolean = false;
  public articleModalProp = {
    categoryId: '',
    articleId: '',
    editing: false,
  };
  public checkoutIntegrationAction: { visible: boolean; value: string | null; status: string | null } = {
    visible: false,
    value: null,
    status: null,
  };

  @venue.State('active') public venue!: Venue;

  @venueLabel.State('items') public venueLabels!: VenueLabel[];
  @venueLabel.Action('fetch') public getVenueLabels!: any;

  @statistics.State('items') public items!: Order[];
  @statistics.State('filter') public filter!: Filter;
  @statistics.State((state) => state.pagination.total) public total!: number;
  @statistics.Action('fetch') public getOrders: any;
  @statistics.Action('setFilter') public setFilter: any;
  @statistics.Action('setItem') public updateStatusOrder: any;

  public async mounted() {
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }
    this.setFilter({
      ...this.filter,
      from: new Date().toISOString().substr(0, 10),
      to: new Date().toISOString().substr(0, 10),
    });
  }

  public async reloadOrders(page: number = 1) {
    if (this.venue && this.venue._id) {
      this.$startLoading('stats');
      this.setFilter({ ...this.filter, venue: this.venue._id });
      await this.getOrders({ page });
      this.$stopLoading('stats');
    }
    this.$stopLoading('venue');
  }

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public onPageChange(page: Page) {
    if (page) {
      this.reloadOrders(page.page);
    }
  }

  public onSearch(queries: any) {
    this.setFilter({ ...this.filter, ...queries });
    this.$refs.table.resetPage();
    this.reloadOrders();
  }

  public exportCsv() {
    this.$startLoading('ordersCsv');
    const csvFilter: Filter = { ...this.filter };
    delete csvFilter.page;
    delete csvFilter.limit;
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .orderStatisticsCsv(csvFilter)
      .then((res: AxiosResponse) => toUrl(res.data, `statistics-order-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('ordersCsv');
      });
  }

  public openEditModal(article: { id: string; category: string }) {
    this.articleModalProp.articleId = article.id;
    this.articleModalProp.categoryId = article.category;
    this.articleModalProp.editing = true;
    this.showEditArticleModal = true;
  }

  public closeEditModal(payload?: any) {
    this.showEditArticleModal = false;
  }

  get statusOrderItems() {
    return [
      { value: OrderStatus.IN_PREPARATION, text: 'In Preparation' },
      { value: OrderStatus.CREATED, text: 'Created' },
      { value: OrderStatus.READY, text: 'Ready' },
      { value: OrderStatus.DONE, text: 'Done' },
      { value: OrderStatus.IN_PAYMENT, text: 'In Payment' },
      { value: OrderStatus.CANCELLED_BY_WAITER, text: 'Cancelled By Waiter' },
      { value: OrderStatus.CANCELLED_BY_CUSTOMER, text: 'Cancelled By Customer' },
      { value: OrderStatus.AWAITING_CONFIRMATION, text: 'Awaiting Confirmation' },
      { value: OrderStatus.NONE, text: 'None' },
      { value: OrderStatus.FAILED, text: 'Failed' },
    ];
  }

  public retry() {
    this.$startLoading('retry-order');
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .retry({
        order: this.checkoutIntegrationAction.value as string,
        status: this.checkoutIntegrationAction.status as OrderStatus,
      })
      .then((res: AxiosResponse<Order>) => {
        if (res && res.data) {
          this.updateStatusOrder(res.data);
        }
        this.notifySuccess('notification.retryCheckoutIntegrationAction');
      })
      .finally(() => {
        this.resetCheckoutIntegrationAction();
        this.$stopLoading('retry-order');
      });
  }

  public retryCheckoutIntegrationAction(order: Order) {
    this.checkoutIntegrationAction.visible = true;
    this.checkoutIntegrationAction.status = null;
    this.checkoutIntegrationAction.value = order._id;
  }

  public resetCheckoutIntegrationAction() {
    this.checkoutIntegrationAction.value = null;
    this.checkoutIntegrationAction.visible = false;
    this.checkoutIntegrationAction.status = null;
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      this.reloadOrders();
    }
  }
}
