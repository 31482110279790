
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Order } from '@/interfaces/models/Order';
import { TableOptions } from '@/interfaces/components/TableOptions';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';
import StatisticFilter from '@/components/statistics/StatisticFilter.vue';
import { Page } from '@/interfaces/api/Page';
import { Article } from '@/interfaces/models/Article';
import PaymentApiService from '@/api/http/PaymentApiService';
import { AxiosResponse } from 'axios';
import { Payment } from '@/interfaces/models/Payment';
import { OrderLog } from '@/interfaces/models/OrderLog';
import OrderLogTable from './OrderLogTable.vue';
import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';
import OrderedArticleList from './OrderedArticleList.vue';
import { isCustomerGroup } from '@/util/helper';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { OrderStatus } from '@/enums/OrderStatus';

@Component({
  components: { StatisticFilter, OrderLogTable, OrderedArticleList },
})
export default class OrderTable extends Vue {
  public payment: Payment | null = null;
  public orderLogs: {
    [key: string]: OrderLog[];
  } = {};

  get accessibleHeaders() {
    return headers.filter((c: ColumnHeader) => {
      if (c.value === 'retry' && this.$can(Permission.ORDER_RETRY) && !this.$isAdmin()) {
        return false;
      }

      if (
        c.value === 'isControlArticle' &&
        this.$can(Permission.ARTICLE_CONTROL_ACTIVATE) &&
        this.$can(Permission.ARTICLE_CONTROL_DEACTIVATE)
      ) {
        return true;
      }

      return c.value !== 'isControlArticle';
    });
  }

  @Prop({ type: Array, required: true }) public venueLabels!: VenueLabel[];
  @Prop({ type: Array, required: true }) public items!: Order[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;

  public search: string = '';
  public expanded: string[] = [];
  public options: TableOptions = {};
  public page: number = 1;
  public rowsPerPage: number = 10;
  public sourceHeaders = this.accessibleHeaders;
  public headers: ColumnHeader[] = this.accessibleHeaders.filter((c: ColumnHeader) => c.default);

  public logsVisible: boolean = false;
  public activeOrderLog: OrderLog | null = null;

  get tableHeaders() {
    return this.headers
      .filter((h: ColumnHeader) => h.value !== 'dndCompanyCode' || isCustomerGroup(CustomerGroup.DeanAndDavid))
      .map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  get pageCount() {
    if (this.pagination) {
      return Math.ceil(this.total / this.rowsPerPage);
    }

    return 0;
  }

  public edit(article: Article) {
    if (!article) return;
    if (this.$can(Permission.ARTICLE_UPDATE)) {
      this.$emit('edit', { id: article._id, category: article.category });
    } else {
      this.$emit('simple-edit', { id: article._id, category: article.category });
    }
  }

  public copy(value: string) {
    if (value) {
      navigator.clipboard.writeText(value);
    }
  }

  public showReceipts(order: Order) {
    this.$startLoading('order.receipts');
    const api: PaymentApiService = new PaymentApiService();
    api
      .showPayment({ payment: order.payment })
      .then((res: AxiosResponse<Payment>) => {
        this.payment = res.data;
      })
      .finally(() => {
        this.$stopLoading('order.receipts');
      });
  }

  public showOrderLogs(order: Order) {
    this.$startLoading('order.logs');
    const api: PaymentApiService = new PaymentApiService();
    api
      .showOrderLogs({ ids: [order._id] })
      .then((res: AxiosResponse<OrderLog[]>) => {
        this.orderLogs[order._id] = res.data;
      })
      .finally(() => {
        this.$stopLoading('order.logs');
      });
  }

  public showOrderLog(orderLog: OrderLog) {
    this.logsVisible = true;
    this.activeOrderLog = orderLog;
  }

  public retryCheckoutIntegrationAction(item: any) {
    this.$emit('retry', item);
  }

  public isExternalOrderProvider(provider: string) {
    return Object.values(ExternalOrderProvider).includes(provider as ExternalOrderProvider);
  }

  public getStatusColor(status: string): string {
    switch (status) {
      case OrderStatus.DONE:
        return '#03C10B';
      case OrderStatus.IN_PREPARATION:
        return '#D77503';
      case OrderStatus.AWAITING_CONFIRMATION:
        return '#DCD302';
      case OrderStatus.FAILED:
        return '#C60202';
      case OrderStatus.CREATED:
        return '#C3C3C3';
      default:
        return '#007BFF';
    }
  }

  getImageSrc(item: { method: string | null }): string | null {
    if (!item || !item.method) {
      return null;
    }

    const methodImages: { [key: string]: string } = {
      pos: require(`@/assets/image/pos.png`),
      uber_eats: require(`@/assets/image/uber.png`),
      paypal: require(`@/assets/image/paypal.png`),
    };
    return methodImages[item.method] || null;
  }

  getImageWidth(item: { method: string | null }): number {
    if (!item || !item.method) {
      return 0;
    }
    return item.method === 'pos' ? 20 : 40;
  }

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    if (this.$route.query.page && parseInt(this.$route.query.page as string, 10) !== this.page) {
      // @ts-ignore
      this.$router.push({ query: { page: this.page, limit: this.rowsPerPage } }).catch((e) => {
        throw Error(`${e}`);
      });
    }

    return { page: this.page, limit: this.rowsPerPage };
  }

  @Emit('change')
  public resetPage() {
    this.options.page = 1;
    return this.options;
  }
}
