
import { Component, Prop, Vue } from 'vue-property-decorator';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import VRangePickerOneField from '@/components/shared/form/VDateRangePickerOneField.vue';
import OrderedArticle from '@/interfaces/models/OrderedArticle';
import { Article } from '@/interfaces/models/Article';
import { Permission } from '@/enums/Permission';

@Component({
  components: { VRangePickerOneField, VColumnSelect },
})
export default class OrderedArticleList extends Vue {
  @Prop({ type: Array, required: true }) public items!: OrderedArticle[];
  @Prop({ type: String, default: '' }) public title!: string;

  public mounted(): void {}

  public edit(article: Article) {
    if (!article) return;
    if (this.$can(Permission.ARTICLE_UPDATE)) {
      this.$emit('edit', { id: article._id, category: article.category });
    } else {
      this.$emit('simple-edit', { id: article._id, category: article.category });
    }
  }
}
