
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Table } from '@/interfaces/models/Table';
import { OrderFlavor } from '@/enums/OrderFlovor';
import { OrderStatus } from '@/enums/OrderStatus';
import { OrderType } from '@/enums/OrderType';
import { Permission } from '@/enums/Permission';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import CustomerGroup from '@/mixins/CustomerGroup';
import { mixins } from 'vue-class-component';
import VColumnSelect from '@/components/shared/table/VColumnSelect.vue';
import VRangePickerOneField from '@/components/shared/form/VDateRangePickerOneField.vue';
import { Range } from '@/interfaces/components/Range';
import moment from 'moment';
import { ExternalOrderProvider } from '@/enums/ExternalOrderProvider';

interface Selection {
  ids: string;
  flavors: OrderFlavor[];
  types: OrderType[];
  status: OrderStatus[];
  tables: Table[];
}

@Component({
  components: { VRangePickerOneField, VColumnSelect },
})
export default class StatisticFilter extends mixins(CustomerGroup) {
  @Prop({ type: Array, default: () => [] }) public tables!: Table[];
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];
  @Prop({ type: Array, default: () => [] }) public sourceHeaders!: any[];

  public ids = '';
  public code = '';
  public selectedFlavors: OrderFlavor[] = [];
  public selectedTypes: OrderType[] = [];
  public selectedStatus: OrderStatus[] = [];
  public selectedTables: string[] = [];
  public selectedLabel: string = '';
  public selectedCustomerGroup: string = '';
  public selectedExternalOrderProvider: string = '';
  public range: Range = {
    from: '',
    to: '',
  };

  public timeout: any;
  public timeoutCodeInput: any;

  public handledFilterDay: string = '';

  get selection() {
    return {
      ids: this.ids,
      flavor: this.selectedFlavors,
      type: this.selectedTypes,
      status: this.selectedStatus,
      table: this.selectedTables,
      venueLabel: this.selectedLabel,
      customerGroup: this.selectedCustomerGroup,
      externalOrderProvider: this.selectedExternalOrderProvider,
      code: this.code.length > 0 ? [this.code] : undefined,
      ...this.range,
    };
  }

  public mounted(): void {
    this.selectedFlavors = this.defaultFlavors;
  }

  get defaultFlavors() {
    if (this.$can(Permission.STATISTICS_FLAVOR)) {
      return [];
    }

    return [OrderFlavor.PROD_RELEASE];
  }

  get flavors(): string[] {
    return Object.values(OrderFlavor);
  }

  get status(): string[] {
    return Object.values(OrderStatus);
  }

  get types(): string[] {
    if (this.$isAdmin()) {
      return Object.values(OrderType);
    }
    return [OrderType.STANDARD, OrderType.PREORDER, OrderType.TERMINAL];
  }

  get externalOrderProviders() {
    return [
      { value: ExternalOrderProvider.JET, text: 'Jet' },
      { value: ExternalOrderProvider.WOLT, text: 'Wolt' },
      { value: ExternalOrderProvider.UBER_EATS, text: 'Uber Eats' },
    ];
  }

  public handleHeaders(event: any) {
    this.$emit('updateHeaders', event);
  }

  public onChangeRange(event: { from: string; to: string }) {
    this.range.from = event.from;
    this.range.to = event.to;
  }

  public applyDay(day: 'today' | 'yesterday') {
    if (day === 'today') {
      this.range.from = moment().subtract(0, 'day').format('YYYY-MM-DD');
      this.range.to = moment().format('YYYY-MM-DD');
    } else if (day === 'yesterday') {
      this.range.from = moment().subtract(1, 'day').format('YYYY-MM-DD');
      this.range.to = moment().subtract(1, 'day').format('YYYY-MM-DD');
    }
    this.$emit('change', this.selection);
    this.handledFilterDay = day;
  }

  public applySelectedFilters() {
    this.handledFilterDay = '';
    this.$emit('change', this.selection);
  }

  @Emit('change')
  public reset() {
    this.ids = '';
    this.code = '';
    this.selectedFlavors = this.defaultFlavors;
    this.selectedTypes = [];
    this.selectedStatus = [];
    this.selectedTables = [];
    this.selectedLabel = '';
    this.selectedCustomerGroup = '';
    this.selectedExternalOrderProvider = '';

    return this.selection;
  }
}
