
import { OrderLog } from '@/interfaces/models/OrderLog';
import { Component, Vue, Prop } from 'vue-property-decorator';
import headers from './headersOrderLog';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';

@Component
export default class OrderLogTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: OrderLog[];

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  public truncatedLogs(item: OrderLog) {
    if (item && item.logs) {
      return item.logs.length > 100 ? `${item.logs.slice(0, 100)}...` : item.logs;
    }
    return '';
  }

  public showMoreButton(item: OrderLog) {
    return item && item.logs && item.logs.length > 100;
  }
}
